export function validate(modelType, parameters) {
  switch (modelType.toLowerCase()) {
    case "canvas":
      return validateCanvas(parameters);

    case "frame":
      return validateFrame(parameters);

    default:
      return false;
  }
}

function validateCanvas(parameters) {
  return (
    "width" in parameters && "height" in parameters && "imageURL" in parameters
  );
}

function validateFrame(parameters) {
  return validateCanvas(parameters);
}

//TODO: add a check for the API key
