import React, { useState } from "react";
import Input, { isPossiblePhoneNumber } from "react-phone-number-input/input";
import axios from "axios";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";

function SMSLinkForwarder(props) {
  let { url, title, styleProperties } = props;

  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("value: ", phoneNumber, url);

    setSubmitted(true);

    const postBody = { artworkName: title, url: url, phoneNumber: phoneNumber };

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/smslink`,
        postBody
      );
      setMessage("sms sent", result.data);
    } catch (ex) {
      console.log("error: ", ex.response);
      setMessage(
        "There was an error sending the SMS, try again or copy-paste the URL to visit this page on your mobile device."
      );
    }
  };

  return (
    <React.Fragment>
      <main className="main text-center">
        <div className="row justify-content-center">
          <div className="my-5 mx-4 col-10">
            <h1
              style={{ color: styleProperties.primaryTextColor }}
              className="heading2"
            >
              Open this page on your phone's browser
            </h1>
            <div className="row my-4 justify-content-sm-center">
              <span
                style={{ color: styleProperties.secondaryTextColor }}
                className="text col-sm-8"
              >
                We can text you the link so you can view{" "}
                {title || "this artwork"} in your space using AR
              </span>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <div className="row no-gutters justify-content-center mb-5">
              <div className="col-sm-4">
                <Input
                  className="newsletter__email"
                  country="US"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  placeholder="Phone Number"
                />
              </div>
              <div className="col-sm-4">
                <div className="clear">
                  <button
                    style={{
                      color: styleProperties.buttonTextColor,
                      backgroundColor: styleProperties.buttonBGColor,
                    }}
                    className="button button--submit w-100 w-md-auto"
                    type="submit"
                    disabled={
                      !(phoneNumber && isPossiblePhoneNumber(phoneNumber)) ||
                      submitted
                    }
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        {message && (
          <div className="row my-4 justify-content-md-center">
            <div className="text col-sm-8">{message}</div>
          </div>
        )}
      </main>
    </React.Fragment>
  );
}

export default SMSLinkForwarder;
