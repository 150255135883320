import React from "react";

function ErrorView(props) {
  const { message } = props;

  return (
    <div>
      <p>{message}</p>
    </div>
  );
}

export default ErrorView;
