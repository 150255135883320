import React from "react";
import ViewInRoomButton from "./ViewInRoomButton";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import ARInstructions from "./ARInstructions";

function USDZLaunchButton(props) {
  const { modelLink, styleProperties } = props;

  return (
    // insert instructions
    <React.Fragment>
      <ARInstructions
        instructions={[
          { id: "instruction1", text: "Tap the View In Room button below" },
          {
            id: "instruction2",
            text: "Slowly pan the camera around the floor and walls",
          },
          { id: "instruction3", text: "Drag the artwork onto the wall" },
        ]}
        styleProperties={styleProperties}
      />
      <div className="row justify-content-center">
        <div className="col-auto justify-content-center">
          <a
            href={`${process.env.REACT_APP_API_BASE_URL}/${modelLink}#allowsContentScaling=0`}
            rel="ar"
            className="justify-content-center"
          >
            <ViewInRoomButton styleProperties={styleProperties} />
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default USDZLaunchButton;
