import React from "react";

function ViewInRoomButton(props) {
  const { virButtonName, buttonBGColor } = props.styleProperties;

  return (
    <img
      src={"/" + virButtonName + ".png"}
      srcSet={"/" + virButtonName + ".png 1x, /" + virButtonName + "@2x.png 2x"}
      alt="view in room button"
      style={{ backgroundColor: buttonBGColor }}
      className="hoverable"
    />
  );
}

export default ViewInRoomButton;
