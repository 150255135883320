import React from "react";

function ARInstructions(props) {
  const { styleProperties, instructions } = props;

  return (
    // insert instructions
    <React.Fragment>
      <div className="row text-center">
        <div className="col-xl-12 py-2 px-4">
          <h3
            style={{
              color: styleProperties.secondaryTextColor,
            }}
          >
            How it works
          </h3>
        </div>
      </div>
      <div className="row text-left">
        <div className="col-xl-12 py-2 px-4">
          <ol>
            {instructions.map((instruction) => (
              <li
                style={{
                  color: styleProperties.secondaryTextColor,

                  fontSize: "24px",
                }}
                className="py-2"
                key={instruction.id}
              >
                {instruction.text}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ARInstructions;
