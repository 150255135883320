import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import LinkForwarder from "./LinkForwarder";
import ModelLoader from "./ModelLoader";
import queryString from "query-string";
import { validate } from "./ModelFunctions";
import ErrorView from "./ErrorView";
import withARSupportCheck from "./withARSupportCheck";
import { styleForKey } from "./styleLookup";

// renders either the model loader or the link forwarder depending on whether or not the user is on a device that can handle the 3D model
function ModelRequestRouter(props) {
  const { modelType } = useParams(); // get the model type from the route

  const params = queryString.parse(props.location.search); //query parameters.
  let valid = validate(modelType, params); // validate the attributes here because we want to make sure they're valid before we send the SMS or load the model to make testing easier.
  let styleProperties = styleForKey(params.key);

  console.log("supports: ", props.supportsAR);
  return (
    <div>
      {!valid && <ErrorView message={"invalid parameters"} />}
      {valid &&
        (props.supportsAR == true ? (
          <ModelLoader
            modelType={modelType}
            parameters={params}
            format={props.arFormat}
            styleProperties={styleProperties}
          />
        ) : (
          <LinkForwarder
            title={params.title}
            styleProperties={styleProperties}
          />
        ))}
    </div>
  );
}

export default withRouter(withARSupportCheck(ModelRequestRouter));
