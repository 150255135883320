import React, { useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";

function SMSLinkForwarder(props) {
  let { url, title, styleProperties } = props;

  return (
    <React.Fragment>
      <main className="main text-center">
        <div className="row justify-content-center">
          <div className="my-1 mx-4 col-10">
            <h1
              style={{ color: styleProperties.primaryTextColor }}
              className="heading2"
            >
              Open this page in Safari
            </h1>
          </div>
        </div>
        <div className="row my-4 justify-content-center">
          <span
            style={{ color: styleProperties.secondaryTextColor }}
            className="text col-sm-8 mx-4"
          >
            This in-app browser does not support Augmented Reality previews.
          </span>
        </div>
      </main>
    </React.Fragment>
  );
}

export default SMSLinkForwarder;
