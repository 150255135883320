import React, { useEffect, useState } from "react";
import { isIOS, isAndroid, isMacOs, getUA } from "react-device-detect";

// Checks for AR support via Apple's a link test and checking for Android.
// Renders passed in component after AR check is complete and passes in supportsAR flag.
function withARSupportCheck(Component) {
  return function WithARSupportCheck(props) {
    const [supportsAR, setSupportsAR] = useState(null);

    const arTestRef = React.createRef();

    useEffect(() => {
      const assumeSupportForAR =
        isIOS || isAndroid || process.env.REACT_APP_ALWAYS_LOAD_MODEL == 1;

      let arSupport =
        arTestRef.current.relList.supports("ar") || assumeSupportForAR;

      setSupportsAR(Boolean(arSupport && !getUA.includes("LinkedInApp")));
    }, []);

    // determine AR format
    const arFormat = isIOS || isMacOs ? "usdz" : "glb";

    return (
      <React.Fragment>
        {supportsAR === null && <a ref={arTestRef} />}
        {supportsAR !== null && (
          <Component {...props} supportsAR={supportsAR} arFormat={arFormat} />
        )}
      </React.Fragment>
    );
  };
}

export default withARSupportCheck;
