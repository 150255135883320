import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ModelRequestRouter from "./ModelRequestRouter";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import React from "react";
import Footer from "./Footer";
import Header from "./Header";

function App() {
  return (
    <React.Fragment>
      <div className="body">
        <div className="row justify-content-center min-vh-100">
          <Router>
            <Header />
            <div className="col-fluid align-self-center">
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => {
                    window.location.href = "https://www.walltowallar.com";
                    return null;
                  }}
                />
                <Route path="/model/:modelType">
                  <ModelRequestRouter />
                </Route>
                <Route
                  path="/test"
                  render={() => {
                    return "192.168.1.4:3000/model/canvas?title=Portal%20X&height=2.03&width=0.91&key=slWRyIgYaO7C&imageURL=https%3A%2F%2Fsharetribe.imgix.net%2F5d6e70ab-1e15-4aef-a76d-d4030afa5fd9%252F5f85be8e-9fb2-4413-ba4a-940f395a8cde%3Fauto%3Dformat%26fit%3Dclip%26h%3D1024%26ixlib%3Djava-1.1.1%26w%3D1024%26s%3D276d2fc0a8ead53e741c0086aec6152e";
                  }}
                />
              </Switch>
            </div>
            <Footer />
          </Router>
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
