import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import USDZLaunchButton from "./USDZLaunchButton";
import GLBModelView from "./GLBModelView";

// loads the requested model
function ModelLoader(props) {
  const { modelType, parameters, format, styleProperties } = props;
  const [modelLink, setModelLink] = useState(null);

  useEffect(() => {
    async function getModel() {
      const params = { ...parameters, format: format };

      if (format === "glb") {
        params["fixedHeight"] = true;
      }

      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/model/${modelType}`,
        { params: params }
      );

      const { modelURL } = result.data;
      setModelLink(modelURL);
    }

    getModel();
  }, []);

  return (
    <main className="main text-center">
      <div className="row justify-content-center">
        {modelLink === null && (
          <div className="col align-self-center">
            <h2
              style={{ color: styleProperties.primaryTextColor }}
              className="heading2"
            >
              {"Loading..."}
            </h2>
          </div>
        )}
        <div className="col-6 col-sm-4 mx-4 my-4 align-self-center">
          {modelLink && (
            <img
              src={parameters.imageURL}
              className="img-fluid"
              alt="artwork"
            />
          )}
        </div>
      </div>
      <div className="row my-2 justify-content-center pb-5">
        <div className="col-10 col-sm-6 align-self-center">
          {modelLink === null && (
            <div
              style={{ color: styleProperties.primaryTextColor }}
              className="spinner-border"
              role="status"
            />
          )}
          {modelLink && format === "usdz" && (
            <USDZLaunchButton
              modelLink={modelLink}
              styleProperties={styleProperties}
            />
          )}
          {modelLink && format === "glb" && (
            <GLBModelView
              modelLink={modelLink}
              styleProperties={styleProperties}
            />
          )}
        </div>
      </div>
    </main>
  );
}

export default ModelLoader;
