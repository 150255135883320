import React from "react";
import "@google/model-viewer";
import ViewInRoomButton from "./ViewInRoomButton";
import ARInstructions from "./ARInstructions";

function GLBModelView(props) {
  const { modelLink, styleProperties } = props;

  return (
    // insert instructions
    <React.Fragment>
      <ARInstructions
        instructions={[
          { id: "instruction1", text: "Tap the View In Room button below" },
          {
            id: "instruction2",
            text: "Slowly pan the camera around the floor and walls",
          },
          {
            id: "instruction3",
            text: "Position the artwork so that the floor alignment line meets the wall",
          },
        ]}
        styleProperties={styleProperties}
      />
      <div className="row justify-content-center">
        <div className="col-auto justify-content-center">
          <a
            href={`intent://arvr.google.com/scene-viewer/1.0?file=${process.env.REACT_APP_API_BASE_URL}/${modelLink}&mode=ar_only&disable_occlusion=true#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`}
            className="justify-content-center"
          >
            <ViewInRoomButton styleProperties={styleProperties} />
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default GLBModelView;
