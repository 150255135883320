import React from "react";
import { withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import { styleForParameters } from "./styleLookup";

function Header(props) {
  const style = styleForParameters(props.location.search);

  return (
    <div className="container">
      <header className="header row">
        <div className="col-md-6 header__left align-items-center d-flex justify-content-md-start justify-content-center">
          <a href={style.website}>
            <img
              src={"/" + style.headerLogoName + ".png"}
              srcSet={
                "/" +
                style.headerLogoName +
                ".png 1x, /" +
                style.headerLogoName +
                "@2x.png 2x"
              }
              alt="logo"
              className="company logo"
            />
          </a>
        </div>
      </header>
    </div>
  );
}

export default withRouter(Header);
