import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import { withRouter } from "react-router-dom";
import { styleForParameters } from "./styleLookup";

function Footer(props) {
  const style = styleForParameters(props.location.search);

  return (
    <footer
      style={{ backgroundColor: style.footerBGColor }}
      className="footer w-100"
    >
      <a href={style.website}>
        <img
          src={"/" + style.footerLogoName + ".png"}
          srcSet={
            "/" +
            style.footerLogoName +
            ".png 1x, /" +
            style.footerLogoName +
            "@2x.png 2x"
          }
          alt="company logo"
          className="footer__logo"
        />
      </a>
      <nav className="footer__links">
        <a
          href={"mailto:" + style.supportEmail}
          style={{ color: style.footerPrimaryTextColor }}
          className="footer__link"
        >
          Contact
        </a>
        <a
          href="https://www.walltowallar.com/terms"
          style={{ color: style.footerPrimaryTextColor }}
          className="footer__link"
        >
          Terms
        </a>
      </nav>
      <span
        style={{ color: style.footerSecondaryTextColor }}
        className="footer__copyright"
      >
        &copy; Wall to Wall. All Rights Reserved.
      </span>
    </footer>
  );
}

export default withRouter(Footer);
