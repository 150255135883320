import React from "react";
import { withRouter } from "react-router-dom";
import { getUA } from "react-device-detect";
import SMSLinkForwarder from "./SMSLinkForwarder";
import UnsupportedBrowser from "./UnsupportedBrowserForwarder";

function LinkForwarder({ title, styleProperties }) {
  const currentURL = window.location.href;

  // in the future, we can decide what type of link forwarding we want to do here:  SMS, QR code, or email link forwarding here.

  if (getUA.includes("LinkedInApp")) {
    return (
      <UnsupportedBrowser
        url={currentURL}
        title={title}
        styleProperties={styleProperties}
      />
    );
  }

  return (
    <SMSLinkForwarder
      url={currentURL}
      title={title}
      styleProperties={styleProperties}
    />
  );
}

export default withRouter(LinkForwarder);
