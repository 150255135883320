import queryString from "query-string";

// returns the styles for a key contained in a url string of parameters
export function styleForParameters(parameterString) {
  const { key } = queryString.parse(parameterString);
  return styleForKey(key);
}

// returns the styles for a given key
export function styleForKey(key) {
  // lookup and see if we can find a style for this key.  If not, return the default style
  if (key in styles) {
    return styles[key];
  } else {
    return styles["default"];
  }
}

const styles = {
  slWRyIgYaO7C: {
    name: "Sugarlift",
    website: "https://www.sugarlift.com/",
    headerLogoName: "sugarlift-footer", // must be png
    footerLogoName: "sugarlift-header", // must be a png
    virButtonName: "virButtonWhiteText",
    supportEmail: "hello@sugarlift.com",
    primaryTextColor: "#4a4a4a",
    secondaryTextColor: "#4a4a4a",
    buttonBGColor: "#2ecc71",
    buttonTextColor: "#FFFFFF",
    footerBGColor: "#FFFFFF",
    footerPrimaryTextColor: "#4a4a4a", // footer links
    footerSecondaryTextColor: "#b2b2b2", //copyright
  },
  default: {
    name: "Wall to Wall",
    website: "https://www.walltowallar.com",
    headerLogoName: "wtw-header",
    footerLogoName: "wtw-footer",
    virButtonName: "virButtonWhiteText",
    supportEmail: "info@walltowall.io",
    primaryTextColor: "#212529",
    secondaryTextColor: "#212529",
    buttonBGColor: "#000000",
    buttonTextColor: "#FFFFFF",
    footerBGColor: "#000000",
    footerPrimaryTextColor: "#959595", // footer links
    footerSecondaryTextColor: "#1f1f1f", //copyright
  },
};
